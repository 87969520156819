const en = {
    serenefireName: 'Serenefire',
    serenefireSlogan: 'Enhance humans. Improve the world.',
    doraName: 'Serenefire Dora',
    doraSlogan: 'Tools make humans stand out.',
    acaciaName: 'Dora Vbox',
    acaciaSlogan: 'The video work manager that can help you become an influencer!',
    mimosaName: 'Dora Cheatsheet',
    mimosaSlogan: 'Cheatsheets make work, play and life more convenient.',
};

export const l10n = en;
