import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getNewses } from '../actions/NewsAction';
import { getProducts } from '../actions/ProductAction';
import { l10n } from '../i18n/Localization';
import NewsesListCard from './NewsesListCard';
import ProductCard from './ProductCard';

class IndexPage extends Component {
  componentDidMount() {
    if (this.props.newses.length === 0) {
      this.props.getNewses();
    }

    if (this.props.products.length === 0) {
      this.props.getProducts();
    }
  }

  render() {
    const { newses, products } = this.props;

    let productCardDivs = products.length ? (
      products.map(product => {
        return (
          <div className="col-12 col-md-6 col-lg-4 mb-3" key={product.id}>
            <ProductCard product={product} showWebsiteLink={true} />
          </div>
        );
      })
    ) : null;

    let content = (
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-9 order-2 order-md-1">
            <div className="row">
              {productCardDivs}
            </div>
          </div>
          <div className="col-12 col-md-3 order-1 order-md-2 mb-3">
            <NewsesListCard newses={newses} />
          </div>
        </div>
      </div>
    );

    return (
      <div className="IndexPage">
        <Helmet>
          <link rel="icon" type="image/x-icon" href="/favicon.ico" />
          <meta name="description" content={l10n.doraName + ', ' + l10n.doraSlogan} />
          <title>{l10n.doraName} - {l10n.doraSlogan}</title>
        </Helmet>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newses: state.newsState.newses,
    products: state.productState.products
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewses: () => {
      dispatch(getNewses());
    },
    getProducts: () => {
      dispatch(getProducts());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
