import firebase from "firebase/app";
import "firebase/app-check";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { firebaseWebSDKConfig, reCaptchaSiteKey } from "../config/config";

firebase.initializeApp(firebaseWebSDKConfig);

const appCheck = firebase.appCheck();
appCheck.activate(reCaptchaSiteKey, true);

export { appCheck };
export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
