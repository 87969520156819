import { CheatsheetActionTypes } from '../actions/CheatsheetAction';

const initStateForWriteCheatsheet = {
    writeCheatsheetCompleted: true,
    writeCheatsheetMessage: '',
    writeCheatsheetDocId: '',
    writeCheatsheetError: null,
};

const initStateForWriteCheatsheetLocalItem = {
    writeCheatsheetLocalItemCompleted: true,
    writeCheatsheetLocalItemMessage: '',
    writeCheatsheetLocalItemDocId: '',
    writeCheatsheetLocalItemError: null,
};

const initState = {
    ...initStateForWriteCheatsheet,
    ...initStateForWriteCheatsheetLocalItem,
};

const CheatsheetReducer = (state = initState, action) => {
    switch (action.type) {
        case CheatsheetActionTypes.WriteCheatsheet:
            return {
                ...state,
                writeCheatsheetCompleted: false,
            };
        case CheatsheetActionTypes.WriteCheatsheetDone:
            if (action.error) {
                return {
                    ...state,
                    writeCheatsheetCompleted: true,
                    writeCheatsheetError: action.error,
                }
            } else {
                return {
                    ...state,
                    writeCheatsheetCompleted: true,
                    writeCheatsheetMessage: 'write-succeeded',
                    writeCheatsheetDocId: action.docId,
                    writeCheatsheetError: null,
                }
            }
        case CheatsheetActionTypes.WriteCheatsheetLocalItem:
            return {
                ...state,
                writeCheatsheetLocalItemCompleted: false,
            };
        case CheatsheetActionTypes.WriteCheatsheetLocalItemDone:
            if (action.error) {
                return {
                    ...state,
                    writeCheatsheetLocalItemCompleted: true,
                    writeCheatsheetLocalItemError: action.error,
                }
            } else {
                return {
                    ...state,
                    writeCheatsheetLocalItemCompleted: true,
                    writeCheatsheetLocalItemMessage: 'write-succeeded',
                    writeCheatsheetLocalItemDocId: action.docId,
                    writeCheatsheetLocalItemError: null,
                }
            }
        default:
            return state;
    }
};

export default CheatsheetReducer;
