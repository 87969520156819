import { functions } from '../firebase/Firebase';
import WriteDataMode from "./WriteDataMode";

export const BlockActionTypes = {
    WriteBlock: 'WriteBlock',
    WriteBlockDone: 'WriteBlockDone',
};

function writeBlockFunction() {
    return functions.httpsCallable('writeBlock');
}

const writeBlock = (mode, block) => {
    return async (dispatch, getState) => {
        try {
            const blockState = getState().blockState;
            const writeBlockCompletedInState = blockState.writeBlockCompleted;
            if (writeBlockCompletedInState === false) {
                return;
            }

            dispatch({
                type: BlockActionTypes.WriteBlock,
            });

            const callable = writeBlockFunction();
            const data = {
                mode: mode.wireName,
                block: block,
            };

            const result = await callable(data);

            dispatch({
                type: BlockActionTypes.WriteBlockDone,
                message: result.data.message,
                docId: result.data.docId,
            });
        } catch (error) {
            dispatch({
                type: BlockActionTypes.WriteBlockDone,
                error: error,
            });
        }
    };
};

export const addBlock = (block) => {
    return writeBlock(WriteDataMode.add, block);
};

export const updateBlock = (block) => {
    return writeBlock(WriteDataMode.update, block);
};

export const deleteBlock = (block) => {
    return writeBlock(WriteDataMode.delete, block);
};
