import { functions } from '../firebase/Firebase';
import WriteDataMode from "./WriteDataMode";

export const CheatsheetActionTypes = {
    WriteCheatsheet: 'WriteCheatsheet',
    WriteCheatsheetDone: 'WriteCheatsheetDone',
    WriteCheatsheetLocalItem: 'WriteCheatsheetLocalItem',
    WriteCheatsheetLocalItemDone: 'WriteCheatsheetLocalItemDone',
};

function writeCheatsheetFunction() {
    return functions.httpsCallable('writeCheatsheet');
}

const writeCheatsheet = (mode, cheatsheet) => {
    return async (dispatch, getState) => {
        try {
            const cheatsheetState = getState().cheatsheetState;
            const writeCheatsheetCompletedInState = cheatsheetState.writeCheatsheetCompleted;
            if (writeCheatsheetCompletedInState === false) {
                return;
            }

            dispatch({
                type: CheatsheetActionTypes.WriteCheatsheet,
            });

            const callable = writeCheatsheetFunction();
            const data = {
                mode: mode.wireName,
                cheatsheet: cheatsheet,
            };

            const result = await callable(data);

            dispatch({
                type: CheatsheetActionTypes.WriteCheatsheetDone,
                message: result.data.message,
                docId: result.data.docId,
            });
        } catch (error) {
            dispatch({
                type: CheatsheetActionTypes.WriteCheatsheetDone,
                error: error,
            });
        }
    };
};

export const addCheatsheet = (cheatsheet) => {
    return writeCheatsheet(WriteDataMode.add, cheatsheet);
};

export const updateCheatsheet = (cheatsheet) => {
    return writeCheatsheet(WriteDataMode.update, cheatsheet);
};

export const deleteCheatsheet = (cheatsheet) => {
    return writeCheatsheet(WriteDataMode.delete, cheatsheet);
};

function writeCheatsheetLocalItemFunction() {
    return functions.httpsCallable('writeCheatsheetLocalItem');
}

const writeCheatsheetLocalItem = (mode, cheatsheetLocalItem) => {
    return async (dispatch, getState) => {
        try {
            const cheatsheetState = getState().cheatsheetState;
            const writeCheatsheetLocalItemCompletedInState = cheatsheetState.writeCheatsheetLocalItemCompleted;
            if (writeCheatsheetLocalItemCompletedInState === false) {
                return;
            }

            dispatch({
                type: CheatsheetActionTypes.WriteCheatsheetLocalItem,
            });

            const callable = writeCheatsheetLocalItemFunction();
            const data = {
                mode: mode.wireName,
                cheatsheetLocalItem: cheatsheetLocalItem,
            };

            const result = await callable(data);

            dispatch({
                type: CheatsheetActionTypes.WriteCheatsheetLocalItemDone,
                message: result.data.message,
                docId: result.data.docId,
            });
        } catch (error) {
            dispatch({
                type: CheatsheetActionTypes.WriteCheatsheetLocalItemDone,
                error: error,
            });
        }
    };
};

export const addCheatsheetLocalItem = (cheatsheetLocalItem) => {
    return writeCheatsheetLocalItem(WriteDataMode.add, cheatsheetLocalItem);
};

export const updateCheatsheetLocalItem = (cheatsheetLocalItem) => {
    return writeCheatsheetLocalItem(WriteDataMode.update, cheatsheetLocalItem);
};

export const deleteCheatsheetLocalItem = (cheatsheetLocalItem) => {
    return writeCheatsheetLocalItem(WriteDataMode.delete, cheatsheetLocalItem);
};
