class WriteDataMode {
    static _nameAdd = 'add';
    static _nameUpdate = 'update';
    static _nameDelete = 'delete';

    static _wireNameAdd = 'add';
    static _wireNameUpdate = 'update';
    static _wireNameDelete = 'delete';

    static _toWire = {
        [this._nameAdd]: this._wireNameAdd,
        [this._nameUpdate]: this._wireNameUpdate,
        [this._nameDelete]: this._wireNameDelete,
    };

    static _fromWire = {
        [this._wireNameAdd]: this._nameAdd,
        [this._wireNameUpdate]: this._nameUpdate,
        [this._wireNameDelete]: this._nameDelete,
    };

    static add = new WriteDataMode(this._nameAdd);
    static update = new WriteDataMode(this._nameUpdate);
    static delete = new WriteDataMode(this._nameDelete);

    static valueOf(name) {
        switch (name) {
            case this._nameAdd:
                return this.add;
            case this._nameUpdate:
                return this.update;
            case this._nameDelete:
                return this.delete;
            default:
                throw new Error('Invalid name for WriteDataMode.');
        }
    }

    static valueOfWireName(wireName) {
        const name = this._fromWire[wireName];
        return this.valueOf(name);
    }

    name;

    constructor(name) {
        this.name = name;
    }

    get wireName() {
        return WriteDataMode._toWire[this.name];
    }
}

export default WriteDataMode;
