import { appCheck, auth } from '../firebase/Firebase';
import { authUrl, adminAppName } from "../config/config";

export const AuthActionTypes = {
  SignIn: 'SignIn',
  SignOut: 'SignOut',
  AuthActionDone: 'AuthActionDone',
};

export const signIn = (email, password) => {
  return async (dispatch, getState) => {
    try {
      let authState = getState().authState;
      let authActionCompletedInState = authState.authActionCompleted;
      if (authActionCompletedInState === false) {
        return;
      }

      dispatch({
        type: AuthActionTypes.SignIn,
      });

      const appCheckTokenRes = await appCheck.getToken(false);

      let res = await fetch(authUrl, {
        method: 'POST',
        headers: new Headers({
          'content-type': 'application/json; charset=utf-8',
          'X-Firebase-AppCheck': appCheckTokenRes.token,
        }),
        body: JSON.stringify({
          'email': email,
          'password': password,
          'adminAppName': adminAppName,
        }),
      });

      let jsonRes = await res.json();
      if (res.status === 200) {
        let customToken = jsonRes['customToken'];
        await auth.signInWithCustomToken(customToken);
      } else {
        throw new Error(`${res.status}, ${jsonRes['message']}`);
      }
    } catch (error) {
      dispatch({
        type: AuthActionTypes.AuthActionDone,
        error: error,
      });
    }
  };
};

export const signOut = () => {
  return async (dispatch, getState) => {
    try {
      let authState = getState().authState;
      let authActionCompletedInState = authState.authActionCompleted;
      if (authActionCompletedInState === false) {
        return;
      }

      dispatch({
        type: AuthActionTypes.SignOut,
      });

      await auth.signOut();
    } catch (error) {
      dispatch({
        type: AuthActionTypes.AuthActionDone,
        error: error,
      });
    }
  };
};

export const authStateChanged = (user) => {
  return (dispatch, getState) => {
    dispatch({
      type: AuthActionTypes.AuthActionDone,
      user: user,
    })
  };
};
