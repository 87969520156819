import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { getArticle } from '../../actions/ArticleAction';
import { getProducts } from '../../actions/ProductAction';
import { l10n } from '../../i18n/Localization';
import ProductCard from '../ProductCard';

class ArticlePage extends Component {
  componentDidMount() {
    let articleId = this.props.articleId;
    if (articleId) {
      this.props.getArticle(articleId);
    }

    if (this.props.products.length === 0) {
      this.props.getProducts();
    }
  }

  render() {
    const { article, products } = this.props;

    let randomIndex = Math.floor(Math.random() * products.length);
    let product = (products.length > 0) ? products[randomIndex] : null;
    let productCard = product ? (
      <ProductCard product={product} showWebsiteLink={false} />
    ) : null;

    let content = (
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-9 mb-3">
            {
              article ? (
                <div>
                  <div>
                    <h1>{article.title}</h1>
                    <div className="text-secondary">{moment(article.updatedAt.toDate()).calendar()}</div>
                  </div>
                  <div className="mt-3" dangerouslySetInnerHTML={{ __html: article.content }}></div>
                </div>
              ) : (
                  <div>loading</div>
                )
            }
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3">
            <div className="row">
              <div className="col-12">
                {productCard}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="ArticlePage">
        {
          article ? (
            <Helmet>
              <link rel="icon" type="image/x-icon" href="/favicons/favicon_acacia.ico" />
              <meta name="description" content={article.title} />
              <title>{article.title} - {l10n.acaciaName}</title>
            </Helmet>
          ) : (
              <Helmet>
                <link rel="icon" type="image/x-icon" href="/favicons/favicon_acacia.ico" />
                <meta name="description" content="Loading article" />
                <title>Loading Article - {l10n.acaciaName}</title>
              </Helmet>
            )
        }
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let articleId = ownProps.match.params.articleId;

  return {
    articleId: articleId,
    article: state.articleState.article,
    products: state.productState.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticle: (articleId) => {
      dispatch(getArticle(articleId));
    },
    getProducts: () => {
      dispatch(getProducts());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePage);
