import { combineReducers } from 'redux';
import ArticleReducer from './ArticleReducer';
import AuthReducer from './AuthReducer';
import BlockReducer from './BlockReducer';
import CheatsheetReducer from './CheatsheetReducer';
import MgmtReducer from './MgmtReducer';
import NewsReducer from './NewsReducer';
import ProductReducer from './ProductReducer';

const RootReducer = combineReducers({
  authState: AuthReducer,
  articleState: ArticleReducer,
  blockState: BlockReducer,
  cheatsheetState: CheatsheetReducer,
  mgmtState: MgmtReducer,
  newsState: NewsReducer,
  productState: ProductReducer,
});

export default RootReducer;
