import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { auth } from './firebase/Firebase';
import { authStateChanged } from './actions/AuthAction';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import IndexPage from './components/IndexPage';
import NotFoundPage from './components/NotFoundPage';
import AcaciaArticlesPage from './components/acacia/ArticlesPage';
import AcaciaArticlePage from './components/acacia/ArticlePage';
import AcaciaMgmtPage from './components/acacia/MgmtPage';
import MimosaMgmtPage from './components/mimosa/MgmtPage';

class App extends Component {
  componentDidMount() {
    this.authUnsubscribe = auth.onAuthStateChanged(user => {
      this.props.authStateChanged(user);
    });
  }

  componentWillUnmount() {
    this.authUnsubscribe();
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/acacia/articles/" component={AcaciaArticlesPage} />
            <Route path="/acacia/articles/:articleId" component={AcaciaArticlePage} />
            <Route path="/acacia/mgmt" component={AcaciaMgmtPage} />
            <Route path="/mimosa/mgmt" component={MimosaMgmtPage} />
            <Route component={NotFoundPage} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authStateChanged: (user) => {
      dispatch(authStateChanged(user));
    },
  };
};

export default connect(null, mapDispatchToProps)(App);
