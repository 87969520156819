import { BlockActionTypes } from '../actions/BlockAction';

const initStateForWriteBlock = {
    writeBlockCompleted: true,
    writeBlockMessage: '',
    writeBlockDocId: '',
    writeBlockError: null,
};

const initState = {
    ...initStateForWriteBlock,
};

const BlockReducer = (state = initState, action) => {
    switch (action.type) {
        case BlockActionTypes.WriteBlock:
            return {
                ...state,
                writeBlockCompleted: false,
            };
        case BlockActionTypes.WriteBlockDone:
            if (action.error) {
                return {
                    ...state,
                    writeBlockCompleted: true,
                    writeBlockError: action.error,
                }
            } else {
                return {
                    ...state,
                    writeBlockCompleted: true,
                    writeBlockMessage: 'write-succeeded',
                    writeBlockDocId: action.docId,
                    writeBlockError: null,
                }
            }
        default:
            return state;
    }
};

export default BlockReducer;
