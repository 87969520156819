export const firebaseWebSDKConfig = {
    apiKey: "AIzaSyACaJtL4UeUVBtlqS2O_oUKovRPijQcmkc",
    authDomain: "dora-cd135.firebaseapp.com",
    databaseURL: "https://dora-cd135.firebaseio.com",
    projectId: "dora-cd135",
    storageBucket: "dora-cd135.appspot.com",
    messagingSenderId: "350756557437",
    appId: "1:350756557437:web:24d48b5087a824714f9714",
    measurementId: "G-7RZPMQY8VP"
};

export const reCaptchaSiteKey = '6LeKXB0iAAAAAE69vvv1eB20XiUIXwkID0K1-wPU';
export const authUrl = "https://auth.serenefire.com/signin.json";
export const adminAppName = "dora-production";
