import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { signIn, signOut } from '../../actions/AuthAction';
import { grantPermissions, createSitemaps } from '../../actions/MgmtAction';
import { l10n } from '../../i18n/Localization';

class MgmtPage extends Component {
    constructor(props) {
        super(props);

        this.refEmail = React.createRef();
        this.refPassword = React.createRef();

        this.refGranteeEmail = React.createRef();
        this.refGranteePermissions = React.createRef();
    }

    handleSignIn = () => {
        let email = this.refEmail.current.value;
        let password = this.refPassword.current.value;
        this.props.signIn(email, password);
    };

    handleSignOut = () => {
        this.props.signOut();
    };

    handleGrantPermissions = () => {
        let data = {
            email: this.refGranteeEmail.current.value,
            permissions: this.refGranteePermissions.current.value.split(','),
        };

        this.props.grantPermissions(data);
    };

    handleCreateSitemaps = () => {
        this.props.createSitemaps();
    }

    render() {
        const { authActionCompleted, user, authActionError } = this.props.authState;
        let authStatus = user ? 'signed in' : 'not signed in';
        let authHint = authActionError ? authActionError.message : '';

        const { grantPermissionsCompleted, grantPermissionsMessage, grantPermissionsError } = this.props.mgmtState;
        let grantPermissionsStatus;
        if (grantPermissionsCompleted) {
            grantPermissionsStatus = grantPermissionsError ? grantPermissionsError.message : grantPermissionsMessage;
        } else {
            grantPermissionsStatus = 'granting';
        }

        const { createSitemapsCompleted, createSitemapsMessage, createSitemapsError } = this.props.mgmtState;
        let createSitemapsStatus;
        if (createSitemapsCompleted) {
            createSitemapsStatus = createSitemapsError ? createSitemapsError.message : createSitemapsMessage;
        } else {
            createSitemapsStatus = 'creating';
        }

        let content = (
            <div className="container mt-3">
                <div className="mb-3">
                    <div id="auth-status">{authStatus}</div>
                    <div id="auth-hint">{authHint}</div>
                    <input className="form-control" type="text" id="email" placeholder="Email" ref={this.refEmail} disabled={!authActionCompleted || user !== null} />
                    <input className="form-control" type="password" id="password" placeholder="Password" ref={this.refPassword} disabled={!authActionCompleted || user !== null} />
                    <button className="btn btn-success mr-2" type="button" id="sign-in" onClick={this.handleSignIn} disabled={!authActionCompleted || user !== null}>Sign in</button>
                    <button className="btn btn-warning" type="button" id="sign-out" onClick={this.handleSignOut} disabled={!authActionCompleted || user === null}>Sign out</button>
                </div>
                <div className="mb-3">
                    <div id="grant-permissions-status">{grantPermissionsStatus}</div>
                    <input className="form-control" type="text" id="grantee-email" placeholder="grantee email" ref={this.refGranteeEmail} />
                    <input className="form-control" type="text" id="grantee-permissions" placeholder="grantee permissions" ref={this.refGranteePermissions} />
                    <button className="btn btn-primary" type="button" id="grant-permissions" onClick={this.handleGrantPermissions}>Grant permissions</button>
                </div>
                <div className="mb-3">
                    <div id="create-sitemaps-status">{createSitemapsStatus}</div>
                    <button className="btn btn-primary" type="button" id="create-sitemaps" onClick={this.handleCreateSitemaps}>Create sitemaps</button>
                </div>
            </div>
        );

        return (
            <div className="MgmtPage">
                <Helmet>
                    <link rel="icon" type="image/x-icon" href="/favicons/favicon_acacia.ico" />
                    <meta name="description" content="Management" />
                    <title>Management - {l10n.acaciaName}</title>
                </Helmet>
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authState: { ...state.authState },
        mgmtState: { ...state.mgmtState },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (email, password) => {
            dispatch(signIn(email, password));
        },
        signOut: () => {
            dispatch(signOut());
        },
        grantPermissions: (data) => {
            dispatch(grantPermissions(data));
        },
        createSitemaps: () => {
            dispatch(createSitemaps());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MgmtPage);
