import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { signIn, signOut } from '../../actions/AuthAction';
import { addBlock, deleteBlock, updateBlock } from '../../actions/BlockAction';
import { addCheatsheet, addCheatsheetLocalItem, deleteCheatsheet, deleteCheatsheetLocalItem, updateCheatsheet, updateCheatsheetLocalItem } from '../../actions/CheatsheetAction';
import { createSitemaps, grantPermissions } from '../../actions/MgmtAction';
import { l10n } from '../../i18n/Localization';

class MgmtPage extends Component {
    constructor(props) {
        super(props);

        this.refEmail = React.createRef();
        this.refPassword = React.createRef();

        this.refGranteeEmail = React.createRef();
        this.refGranteePermissions = React.createRef();

        this.refCheatsheetId = React.createRef();
        this.refCheatsheetFormatCode = React.createRef();
        this.refCheatsheetContentL10nLocaleCode = React.createRef();
        this.refCheatsheetCurrencyCode = React.createRef();
        this.refCheatsheetCurrencyCount = React.createRef();
        this.refCheatsheetThemeCheatsheetBgColor = React.createRef();
        this.refCheatsheetThemeCheatsheetTextColor = React.createRef();
        this.refCheatsheetThemeCheatsheetIconColor = React.createRef();
        this.refCheatsheetThemeBlockBgColor = React.createRef();
        this.refCheatsheetThemeBlockTextColor = React.createRef();
        this.refCheatsheetThemeUnitTextColor = React.createRef();
        this.refCheatsheetThemeTableUnitStripeColor = React.createRef();
        this.refCheatsheetThemeTableUnitBorderColor = React.createRef();
        this.refCheatsheetTags = React.createRef();
        this.refCheatsheetFunctionTags = React.createRef();
        this.refCheatsheetDefaultL10nLocaleCode = React.createRef();

        this.refCheatsheetLocalItemId = React.createRef();
        this.refCheatsheetLocalItemCheatsheetId = React.createRef();
        this.refCheatsheetLocalItemL10nLocaleCode = React.createRef();
        this.refCheatsheetLocalItemTitle = React.createRef();
        this.refCheatsheetLocalItemDescription = React.createRef();

        this.refBlockId = React.createRef();
        this.refBlockCheatsheetId = React.createRef();
        this.refBlockAreaCode = React.createRef();
        this.refBlockSequence = React.createRef();
        this.refBlockTitle = React.createRef();
        this.refBlockContent = React.createRef();
        this.refBlockAllowPreview = React.createRef();
    }

    handleSignIn = () => {
        let email = this.refEmail.current.value;
        let password = this.refPassword.current.value;
        this.props.signIn(email, password);
    };

    handleSignOut = () => {
        this.props.signOut();
    };

    handleGrantPermissions = () => {
        let data = {
            email: this.refGranteeEmail.current.value,
            permissions: this.refGranteePermissions.current.value.split(','),
        };

        this.props.grantPermissions(data);
    };

    handleCreateSitemaps = () => {
        this.props.createSitemaps();
    }

    _createCheatsheet() {
        const cheatsheet = {
            id: this.refCheatsheetId.current.value,
            formatCode: this.refCheatsheetFormatCode.current.value,
            contentL10nLocaleCode: this.refCheatsheetContentL10nLocaleCode.current.value,
            currencyCode: this.refCheatsheetCurrencyCode.current.value,
            currencyCount: Number.parseInt(this.refCheatsheetCurrencyCount.current.value),
            theme: {
                cheatsheetBgColor: this.refCheatsheetThemeCheatsheetBgColor.current.value,
                cheatsheetTextColor: this.refCheatsheetThemeCheatsheetTextColor.current.value,
                cheatsheetIconColor: this.refCheatsheetThemeCheatsheetIconColor.current.value,
                blockBgColor: this.refCheatsheetThemeBlockBgColor.current.value,
                blockTextColor: this.refCheatsheetThemeBlockTextColor.current.value,
                unitTextColor: this.refCheatsheetThemeUnitTextColor.current.value,
                tableUnitStripeColor: this.refCheatsheetThemeTableUnitStripeColor.current.value,
                tableUnitBorderColor: this.refCheatsheetThemeTableUnitBorderColor.current.value,
            },
            tags: this.refCheatsheetTags.current.value.split(','),
            functionTags: this.refCheatsheetFunctionTags.current.value.split(','),
            defaultL10nLocaleCode: this.refCheatsheetDefaultL10nLocaleCode.current.value,
        };

        return cheatsheet;
    }

    _addCheatsheet = () => {
        const cheatsheet = this._createCheatsheet();

        this.props.addCheatsheet(cheatsheet);
    };

    _updateCheatsheet = () => {
        const cheatsheet = this._createCheatsheet();

        this.props.updateCheatsheet(cheatsheet);
    }

    _deleteCheatsheet = () => {
        const cheatsheet = this._createCheatsheet();

        this.props.deleteCheatsheet(cheatsheet);
    }

    _createCheatsheetLocalItem() {
        const cheatsheetLocalItem = {
            id: this.refCheatsheetLocalItemId.current.value,
            cheatsheetId: this.refCheatsheetLocalItemCheatsheetId.current.value,
            l10nLocaleCode: this.refCheatsheetLocalItemL10nLocaleCode.current.value,
            title: this.refCheatsheetLocalItemTitle.current.value,
            description: this.refCheatsheetLocalItemDescription.current.value,
        };

        return cheatsheetLocalItem;
    }

    _addCheatsheetLocalItem = () => {
        const cheatsheetLocalItem = this._createCheatsheetLocalItem();

        this.props.addCheatsheetLocalItem(cheatsheetLocalItem);
    };

    _updateCheatsheetLocalItem = () => {
        const cheatsheetLocalItem = this._createCheatsheetLocalItem();

        this.props.updateCheatsheetLocalItem(cheatsheetLocalItem);
    }

    _deleteCheatsheetLocalItem = () => {
        const cheatsheetLocalItem = this._createCheatsheetLocalItem();

        this.props.deleteCheatsheetLocalItem(cheatsheetLocalItem);
    }

    _createBlock() {
        const block = {
            id: this.refBlockId.current.value,
            cheatsheetId: this.refBlockCheatsheetId.current.value,
            areaCode: this.refBlockAreaCode.current.value,
            sequence: Number.parseInt(this.refBlockSequence.current.value),
            title: this.refBlockTitle.current.value,
            content: this.refBlockContent.current.value,
            allowPreview: this.refBlockAllowPreview.current.checked,
        };

        return block;
    }

    _addBlock = () => {
        const block = this._createBlock();

        this.props.addBlock(block);
    };

    _updateBlock = () => {
        const block = this._createBlock();

        this.props.updateBlock(block);
    }

    _deleteBlock = () => {
        const block = this._createBlock();

        this.props.deleteBlock(block);
    }

    render() {
        const { authActionCompleted, user, authActionError } = this.props.authState;
        let authStatus = user ? 'signed in' : 'not signed in';
        let authHint = authActionError ? authActionError.message : '';

        const { grantPermissionsCompleted, grantPermissionsMessage, grantPermissionsError } = this.props.mgmtState;
        let grantPermissionsStatus;
        if (grantPermissionsCompleted) {
            grantPermissionsStatus = grantPermissionsError ? grantPermissionsError.message : grantPermissionsMessage;
        } else {
            grantPermissionsStatus = 'granting';
        }

        const { createSitemapsCompleted, createSitemapsMessage, createSitemapsError } = this.props.mgmtState;
        let createSitemapsStatus;
        if (createSitemapsCompleted) {
            createSitemapsStatus = createSitemapsError ? createSitemapsError.message : createSitemapsMessage;
        } else {
            createSitemapsStatus = 'creating';
        }

        const { writeCheatsheetCompleted, writeCheatsheetMessage, writeCheatsheetDocId, writeCheatsheetError } = this.props.cheatsheetState;
        let writeCheatsheetStatus;
        let writeCheatsheetId;
        if (writeCheatsheetCompleted) {
            writeCheatsheetStatus = writeCheatsheetError ? writeCheatsheetError.message : writeCheatsheetMessage;
            writeCheatsheetId = writeCheatsheetError ? '' : writeCheatsheetDocId;
        } else {
            writeCheatsheetStatus = 'writing';
            writeCheatsheetId = '';
        }

        const { writeCheatsheetLocalItemCompleted, writeCheatsheetLocalItemMessage, writeCheatsheetLocalItemDocId, writeCheatsheetLocalItemError } = this.props.cheatsheetState;
        let writeCheatsheetLocalItemStatus;
        let writeCheatsheetLocalItemId;
        if (writeCheatsheetLocalItemCompleted) {
            writeCheatsheetLocalItemStatus = writeCheatsheetLocalItemError ? writeCheatsheetLocalItemError.message : writeCheatsheetLocalItemMessage;
            writeCheatsheetLocalItemId = writeCheatsheetLocalItemError ? '' : writeCheatsheetLocalItemDocId;
        } else {
            writeCheatsheetLocalItemStatus = 'writing';
            writeCheatsheetLocalItemId = '';
        }

        const { writeBlockCompleted, writeBlockMessage, writeBlockDocId, writeBlockError } = this.props.blockState;
        let writeBlockStatus;
        let writeBlockId;
        if (writeBlockCompleted) {
            writeBlockStatus = writeBlockError ? writeBlockError.message : writeBlockMessage;
            writeBlockId = writeBlockError ? '' : writeBlockDocId;
        } else {
            writeBlockStatus = 'writing';
            writeBlockId = '';
        }

        const content = (
            <div className="container mt-3">
                <div className="mb-3">
                    <div id="auth-status">{authStatus}</div>
                    <div id="auth-hint">{authHint}</div>
                    <input className="form-control" type="text" id="email" placeholder="Email" ref={this.refEmail} disabled={!authActionCompleted || user !== null} />
                    <input className="form-control" type="password" id="password" placeholder="Password" ref={this.refPassword} disabled={!authActionCompleted || user !== null} />
                    <button className="btn btn-success mr-2" type="button" id="sign-in" onClick={this.handleSignIn} disabled={!authActionCompleted || user !== null}>Sign in</button>
                    <button className="btn btn-warning" type="button" id="sign-out" onClick={this.handleSignOut} disabled={!authActionCompleted || user === null}>Sign out</button>
                </div>
                <div className="mb-3">
                    <div id="grant-permissions-status">{grantPermissionsStatus}</div>
                    <input className="form-control" type="text" id="grantee-email" placeholder="grantee email" ref={this.refGranteeEmail} />
                    <input className="form-control" type="text" id="grantee-permissions" placeholder="grantee permissions" ref={this.refGranteePermissions} />
                    <button className="btn btn-primary" type="button" id="grant-permissions" onClick={this.handleGrantPermissions}>Grant permissions</button>
                </div>
                <div className="mb-3">
                    <div id="create-sitemaps-status">{createSitemapsStatus}</div>
                    <button className="btn btn-primary" type="button" id="create-sitemaps" onClick={this.handleCreateSitemaps}>Create sitemaps</button>
                </div>
                <div className="mb-3">
                    <h6>Cheatsheet</h6>
                    <div>{writeCheatsheetStatus}</div>
                    <div>{writeCheatsheetId}</div>
                    <input ref={this.refCheatsheetId} className='form-control' type='text' placeholder='id' />
                    <input ref={this.refCheatsheetFormatCode} className='form-control' type='text' placeholder='formatCode' />
                    <input ref={this.refCheatsheetContentL10nLocaleCode} className='form-control' type='text' placeholder='contentL10nLocaleCode' />
                    <input ref={this.refCheatsheetCurrencyCode} className='form-control' type='text' placeholder='currencyCode' />
                    <input ref={this.refCheatsheetCurrencyCount} className='form-control' type='number' placeholder='currencyCount' step='1' />
                    <input ref={this.refCheatsheetThemeCheatsheetBgColor} className='form-control' type='text' placeholder='themeCheatsheetBgColor' />
                    <input ref={this.refCheatsheetThemeCheatsheetTextColor} className='form-control' type='text' placeholder='themeCheatsheetTextColor' />
                    <input ref={this.refCheatsheetThemeCheatsheetIconColor} className='form-control' type='text' placeholder='themeCheatsheetIconColor' />
                    <input ref={this.refCheatsheetThemeBlockBgColor} className='form-control' type='text' placeholder='themeBlockBgColor (optional)' />
                    <input ref={this.refCheatsheetThemeBlockTextColor} className='form-control' type='text' placeholder='themeBlockTextColor' />
                    <input ref={this.refCheatsheetThemeUnitTextColor} className='form-control' type='text' placeholder='themeUnitTextColor' />
                    <input ref={this.refCheatsheetThemeTableUnitStripeColor} className='form-control' type='text' placeholder='themeTableUnitStripeColor (optional)' />
                    <input ref={this.refCheatsheetThemeTableUnitBorderColor} className='form-control' type='text' placeholder='themeTableUnitBorderColor' />
                    <input ref={this.refCheatsheetTags} className='form-control' type='text' placeholder='tags (optional)' />
                    <input ref={this.refCheatsheetFunctionTags} className='form-control' type='text' placeholder='functionTags (optional)' />
                    <input ref={this.refCheatsheetDefaultL10nLocaleCode} className='form-control' type='text' placeholder='defaultL10nLocaleCode' />
                    <button className="btn btn-primary mr-2" type="button" onClick={this._addCheatsheet}>Add</button>
                    <button className="btn btn-warning mr-2" type="button" onClick={this._updateCheatsheet}>Update</button>
                    <button className="btn btn-danger" type="button" disabled={true} onClick={this._deleteCheatsheet}>Delete</button>
                </div>
                <div className="mb-3">
                    <h6>CheatsheetLocalItem</h6>
                    <div>{writeCheatsheetLocalItemStatus}</div>
                    <div>{writeCheatsheetLocalItemId}</div>
                    <input ref={this.refCheatsheetLocalItemId} className='form-control' type='text' placeholder='id' />
                    <input ref={this.refCheatsheetLocalItemCheatsheetId} className='form-control' type='text' placeholder='cheatsheetId' />
                    <input ref={this.refCheatsheetLocalItemL10nLocaleCode} className='form-control' type='text' placeholder='l10nLocaleCode' />
                    <input ref={this.refCheatsheetLocalItemTitle} className='form-control' type='text' placeholder='title' />
                    <input ref={this.refCheatsheetLocalItemDescription} className='form-control' type='text' placeholder='description' />
                    <button className="btn btn-primary mr-2" type="button" onClick={this._addCheatsheetLocalItem}>Add</button>
                    <button className="btn btn-warning mr-2" type="button" onClick={this._updateCheatsheetLocalItem}>Update</button>
                    <button className="btn btn-danger" type="button" disabled={true} onClick={this._deleteCheatsheetLocalItem}>Delete</button>
                </div>
                <div className="mb-3">
                    <h6>Block</h6>
                    <div>{writeBlockStatus}</div>
                    <div>{writeBlockId}</div>
                    <input ref={this.refBlockId} className='form-control' type='text' placeholder='id' />
                    <input ref={this.refBlockCheatsheetId} className='form-control' type='text' placeholder='cheatsheetId' />
                    <input ref={this.refBlockAreaCode} className='form-control' type='text' placeholder='areaCode' />
                    <input ref={this.refBlockSequence} className='form-control' type='number' placeholder='sequence' step='1' />
                    <input ref={this.refBlockTitle} className='form-control' type='text' placeholder='title' />
                    <textarea ref={this.refBlockContent} className='form-control' placeholder='content' rows='10' />
                    <div className='form-group'>
                        <div className='form-check'>
                            <input ref={this.refBlockAllowPreview} className='form-check-input' type='checkbox' defaultChecked={false} />
                            <label className='form-check-label'>allowPreview</label>
                        </div>
                    </div>
                    <button className="btn btn-primary mr-2" type="button" onClick={this._addBlock}>Add</button>
                    <button className="btn btn-warning mr-2" type="button" onClick={this._updateBlock}>Update</button>
                    <button className="btn btn-danger" type="button" disabled={true} onClick={this._deleteBlock}>Delete</button>
                </div>
            </div>
        );

        return (
            <div className="MgmtPage">
                <Helmet>
                    <link rel="icon" type="image/x-icon" href="/favicons/favicon_mimosa.ico" />
                    <meta name="description" content="Management" />
                    <title>Management - {l10n.mimosaName}</title>
                </Helmet>
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authState: {
            ...state.authState,
        },
        mgmtState: {
            ...state.mgmtState,
        },
        cheatsheetState: {
            ...state.cheatsheetState,
        },
        blockState: {
            ...state.blockState,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (email, password) => {
            dispatch(signIn(email, password));
        },
        signOut: () => {
            dispatch(signOut());
        },
        grantPermissions: (data) => {
            dispatch(grantPermissions(data));
        },
        createSitemaps: () => {
            dispatch(createSitemaps());
        },
        addCheatsheet: (cheatsheet) => {
            dispatch(addCheatsheet(cheatsheet));
        },
        updateCheatsheet: (cheatsheet) => {
            dispatch(updateCheatsheet(cheatsheet));
        },
        deleteCheatsheet: (cheatsheet) => {
            dispatch(deleteCheatsheet(cheatsheet));
        },
        addCheatsheetLocalItem: (cheatsheetLocalItem) => {
            dispatch(addCheatsheetLocalItem(cheatsheetLocalItem));
        },
        updateCheatsheetLocalItem: (cheatsheetLocalItem) => {
            dispatch(updateCheatsheetLocalItem(cheatsheetLocalItem));
        },
        deleteCheatsheetLocalItem: (cheatsheetLocalItem) => {
            dispatch(deleteCheatsheetLocalItem(cheatsheetLocalItem));
        },
        addBlock: (block) => {
            dispatch(addBlock(block));
        },
        updateBlock: (block) => {
            dispatch(updateBlock(block));
        },
        deleteBlock: (block) => {
            dispatch(deleteBlock(block));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MgmtPage);
